import { Directive, ElementRef, HostListener, Input } from '@angular/core';

@Directive({
  selector: '[pbbImgError]',
  standalone: true,
})
export class PbbImgErrorDirective {
  nativeEl: HTMLImageElement;
  @Input() pbbImgError: string = '';
  @HostListener('error') onError() {
    if (this.pbbImgError.length > 1) {
      this.nativeEl.src = this.pbbImgError;
    }
  }
  constructor(private elem: ElementRef) {
    this.nativeEl = this.elem.nativeElement;
  }
}
