import { HttpBackend, HttpClient } from '@angular/common/http';
import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { lastValueFrom } from 'rxjs';

export interface ImgBase {
  assetType: string;
  contentBase64: string;
}

@Pipe({
  name: 'base64',
  standalone: true,
})
export class Base64Pipe implements PipeTransform {
  public httpClient: HttpClient;
  constructor(private handler: HttpBackend, public sanitizer: DomSanitizer, private window: Window) {
    this.httpClient = new HttpClient(handler);
  }
  async transform(url: string | undefined): Promise<SafeUrl> {
    if (!url) {
      return '';
    }
    const sessionImage = sessionStorage.getItem(url || '');

    if (sessionImage) {
      return this.sanitizer.bypassSecurityTrustUrl(sessionImage);
    }
    const CACHE = await this.window.caches.open('logo');
    const cachedRes = await CACHE.match(url);
    let res: ImgBase;
    if (cachedRes) {
      res = await cachedRes.json();
    } else {
      res = await lastValueFrom(this.httpClient.get<ImgBase>(url));
    }
    const imgRes = `data:${res.assetType};base64, ${res.contentBase64}`;
    sessionStorage.setItem(url, imgRes);
    CACHE.add(url);
    return this.sanitizer.bypassSecurityTrustUrl(imgRes);
  }
}
